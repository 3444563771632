function Privacy() {
  return (
    <div className="page">
    	<div className="app-body-wrapper">
		    <div className="app-body animate__animated animate__fadeInUpBig">
		      &nbsp;
		      <br />
		      <div style={{'text-align': 'center'}}>
		      	PRIVACY POLICY
		      </div>

		      <br />
		      COOKIES 
		      <br /><br />
				When you visit a website, the website logs information to the browser, creating a text file called a cookie. Cookies collect standard internet and visitor behavior information, such as whether you are logged in, what you’ve placed in your shopping cart, or what preferences you have set. Merchspider may collect analytics information from you automatically through cookies or similar technology.
		      <br />
				For further information, visit <a href="https://allaboutcookies.org">allaboutcookies.org</a>

		      <br /><br /><br />
				HOW TO CONTACT US
		      <br /><br />
				If you have any questions about Merchspider’s privacy policy, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
		      	<br />
				Email us at <a href="mailto:merchspider@gmail.com">merchspider@gmail.com</a>

		      <br /><br /><br />
				CHANGES TO OUR PRIVACY POLICY
		      <br /><br />
				This privacy policy was last updated on July 15, 2021.
				
		    </div>
	    </div>

	    <br /> <br />&nbsp;
	    <br /> <br />&nbsp;

    </div>
  )
}

export default Privacy
