import { Finder } from '../components/Finder'
import { useLocation } from 'react-router-dom'

function useBcUsernameFromRoute() {
	const location = useLocation()
	const locationArray = location.pathname.split('/')
	if (locationArray && locationArray.length > 2 && locationArray[1] === 'find') {
		return locationArray[2]
	}
	return false
}

function Find() {
	const bcUsername = useBcUsernameFromRoute()

  return (
    <div className="page">
    	<header className="home-header">
		    <div className="animate__animated animate__fadeInUp">
		      Find your own merch!
		    </div>
	    </header>

	    <div className="home-sub-header animate__animated animate__fadeInUpBig" id='find-subtitle'>
	    	Enter your bandcamp username to find merch from the artists you listen to, and the people you follow.
	    </div>

	    <br /><br />

	    <Finder profile={bcUsername}/>

    </div>
  )
}

export default Find
