import React from "react"

export class Loader extends React.Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {

  }

  render () {
    const ratio = 1 / 15
    const minGirth = 3
    const size = this.props.size || 120
    const color = this.props.color || `var(--normal)`
    const background = this.props.background || `var(--dark)`
    const padding = this.props.padding || 0
    
    let girth = ratio * size
    if (girth < minGirth) girth = minGirth 

    const style = {
      width: size + 'px',
      height: size + 'px',
      border: girth + 'px solid ' + color,
      borderTop: girth + 'px solid ' + background
    }

    if (this.props.loading) {
      return (
        <div className="loader-wrapper" style={{padding: padding + 'px 5px'}}>
          <div className="loader" style={style}>

          </div>
        </div>
      )
    }
    return (
      <div> </div>
    )
  }
}
