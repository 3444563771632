// const merchApiUrl = 'http://localhost:5001/merch-spider-api/us-central1/app/'
const merchApiUrl = 'https://us-central1-merch-spider-api.cloudfunctions.net/app/'

async function get (endpoint, params, api) {
	if (!api) api = merchApiUrl
	let url =  api + endpoint
	if (params) {
		url = makeQueryParams(url, params)
	}
	const result = await fetch(url, {
      method: "GET"
    })
	return result.json()
}

async function post (endpoint, body) {
	const url =  merchApiUrl + endpoint
	const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body)
    })
	return result.json()
}

function makeQueryParams(url, params) {
	const paramArr = Object.entries(params)
	return paramArr.reduce((acc, param, i) => {
    	return acc + param[0] + '=' + param[1] + (i < paramArr.length-1? '&' : '')
	}, url + '?')
}

function buildBandcampHref (merch) {
	if (!merch.href) return 'https://' + merch.artist + '.bandcamp.com/'
	return 'https://' + merch.artist + '.bandcamp.com/merch/'+ merch.href
}

function buildBandcampProfileHref (profile) {
	return 'https://bandcamp.com/' + profile
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function cropString (s, i) {
	if (!s || s.length < i) return s
	return s.slice(0, i - 3).concat('...')
}


const exports = {
	buildBandcampProfileHref,
	buildBandcampHref,
	decodeHtml,
	cropString,
	post,
	get
}

export default exports