import React from "react"

import { Loader } from './Loader'


export class Button extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  componentDidMount () {

  }

  componentDidUpdate (props) {
    if (props.loading && !this.props.loading) {
      this.setState({ loading: false })
    }
  }

  handleClick(click) {
    this.createRipple(click)
    setTimeout(() => {
      this.checkLoader()
    }, 10)
  }

  createRipple(event) {
    const button = event.currentTarget;

    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.backgroundColor = button.style.color
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  checkLoader () {
    setTimeout(() => {
      if (this.props.loading) {
        this.setState({ loading: true })
      } else {
        this.setState({ loading: false })
      }
    }, 400)
  }

  getLoader () {
    return (
      <div>
        <Loader loading={true} size={12} color={this.props.color} />
      </div>
    )
  }

  render () {
    const body = this.state.loading ? this.getLoader() : this.props.text
    const style = {
      color: this.props.color || 'var(--normal)',
      'backgroundColor': this.props.background || 'var(--backgroundBody)',
      'borderColor': this.props.border || this.props.color|| 'var(--normal)'
    }

    return (
      <button
        style={style}
        onClick={(e) => this.handleClick(e)}
        id={this.props.buttonId} >{body}
      </button>
    )
  }

}
