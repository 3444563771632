import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import 'animate.css'

import Home from './pages/Home.js';
import Login from './pages/Login.js';
import Find from './pages/Find.js';
import About from './pages/About.js';
import Privacy from './pages/Privacy.js';
import { faSpider } from '@fortawesome/free-solid-svg-icons'

export default function App() {
  const [navToggle, setNavToggle] = useState(false);

  return (
    <div className="app">
      <div className="app-nav-bar">
        <a href='/' className="app-title-text">
          <FontAwesomeIcon className="app-nav-logo" icon={faSpider}/> Merchspider
        </a>

        <div onClick={() => setNavToggle(!navToggle)}>
          <FontAwesomeIcon 
            icon={faBars} 
            className={navToggle ? "app-burger rotate-clockwise" : "app-burger rotate-reset"}/>
        </div>
      </div>

      <Router>
        <div> 
          <div className={navToggle ? "app-router" : "app-router app-router-up"} onClick={() => setNavToggle(!navToggle)}>
            <Link to="/" className="app-link">Home</Link>
            <Link to="/find" className="app-link">Find Merch</Link>
            <Link to="/about" className="app-link">About</Link>
            <Link to="/login" className="app-link">Login</Link>
          </div> 
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/find">
              <Find />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
          </Switch>
        </div>
      </Router>

      <div className="app-footer">
        Made by <a href="https://github.com/collinbarlage">Collin</a>
        <br />
        <a href="https://github.com/collinbarlage">
          <img src="github.svg" alt="" className="app-footer-img"/>
        </a>
      </div>


    </div>
  );
}