import { MerchFeed } from '../components/MerchFeed'

function Home() {
  return (
  	<div className="page">
	    <header className="home-header">
		    <div className="animate__animated animate__fadeInUp">
			    
			 </div>
		    <div className="animate__animated animate__fadeInUpBig">
		      Support Artists, Get Merch
		    </div>
	    </header>

	    <div className="home-sub-header animate__animated animate__fadeInUpBig">
	    	Browse unique merch from independent artists discovered from &thinsp;
	    	<a href="https://bandcamp.com" style={{'color': 'var(--bandcamp)'}}>bandcamp.com</a> <br /> <br />
	    	<a href="about" style={{'color': 'var(--bandcamp)'}}>
	    		Learn more
	    	</a>
	    </div>

	    <MerchFeed key='home'/>

    </div>
  )
}

export default Home
