import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Button } from './Button'
import { MerchFeed } from './MerchFeed'
import { TypeAheadDropDown } from './TypeAheadDropDown'

import utils from "../utils"

export class Finder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      profilefeed: '',
      bcUsername: '',
      profile: false,
      initialProfile: props.profile,
      loading: false
    }
    this.handleTextChange = this.handleTextChange.bind(this)
  }

  componentDidMount () {
    this.listenToForm()
    if (this.state.initialProfile) {
      this.autoSubmitUsername(this.state.initialProfile)
    }
  }

  autoSubmitUsername(username) {
    let bcUsernameInput = document.getElementById("bcUsername")
    bcUsernameInput.value = username
    this.handleSubmit(username)
  }

  listenToForm() {
    const self = this
    const finderForm = document.getElementById('finderForm')
    const bcUsernameInput = document.getElementById('bcUsername')

    finderForm.onsubmit = function() {
        const bcUsername = bcUsernameInput.value
        const newUrl = window.location.origin + '/find/' + bcUsername
        if (!!self.state.profile) { // if we already loaded a profile
          // start over with another username
          window.location.href = newUrl
          return false
        }
        window.history.pushState(bcUsername, '', newUrl)
        self.handleSubmit(bcUsername)
        return false
    }
  }

  handleTextChange (e) {
    let bcUsernameInput = document.getElementById("bcUsername").value
    this.setState({
      bcUsername: bcUsernameInput
    })
  }

  async handleSubmit(bcUsername) {
    this.setState({
      loading: true
    })

    const profile = await utils.get('profile', {
      profile: bcUsername
    })

    this.setState({
      profilefeed: this.renderProfile(profile),
      profile: profile,
      loading: false
    })

  }

  getProfileError (profile) {
    return (
      <div className="merch-error">
        {profile.error}
      </div>
    )
  }

  renderBcLink () {
    if (this.state.profile) return (<div></div>)
    return (
      <div className="app-center find-bc">
        Don't have a bandcamp account?<br />
        <a href="https://bandcamp.com"
          style={{'color': 'var(--bandcamp)'}}
          target="_blank"
          rel="noopener noreferrer"
        >
          make one here
        </a>
      </div>
    )
  }

  renderProfile (profile) {
    if (!profile) return (<div></div>)
    if (profile.error) return this.getProfileError(profile)

    return (
      <div>
        <div className="app-center find-spacer"> got your profile! finding artists...</div>
        <MerchFeed profile={profile} />
      </div>
    )
  }

  render () {
    return (
      <div className="fade-in">
        <form className="login-form " autoComplete="off" id='finderForm'>
          <div className="group">      
            <input type="text" onChange={this.handleTextChange} id='bcUsername' required />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>bandcamp username</label>
          </div>
          <Button
            text='Find Merch'
            color='var(--submit)'
            loading={this.state.loading}
            buttonId='finderButton'
          />
        </form>

        <TypeAheadDropDown
          text={this.state.bcUsername}
          loading={this.state.loading}
        />

        {this.state.profilefeed}
        {this.renderBcLink()}
      </div>
    )
  }
}
