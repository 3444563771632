
function Login() {
  return (
    <div className="page">
    	<header className="home-header">
		    <div className=" animate__animated animate__fadeInUp">
		      Login
		    </div>
	    </header>

		<form className="login-form animate__animated animate__fadeInUpBig">
		    <div className="group">      
		      <input type="text" required />
		      <span className="highlight"></span>
		      <span className="bar"></span>
		      <label>Username</label>
		    </div>
		      
		    <div className="group">      
		      <input type="password" required />
		      <span className="highlight"></span>
		      <span className="bar"></span>
		      <label>Password</label>
		    </div>
		</form>

    </div>
  )
}

export default Login
