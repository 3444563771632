import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './Loader'

import utils from "../utils"

export class MerchFeed extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      profile: {}, // current profile set to recurse
      hydratedFans: [], // list of all hydrated profile objects
      merchfeed: [], // html of merch list
      artists: [], // artists visited (string)
      merch: [], // merch objects recieved
      isScrolling: false,
      lazyLoading: true,
      loading: true,
      empty: false,
      profileIndex: 0, // index of profile within hydratedFans
      fanIndex: 0, // index of of profile hydrated for artists
      index: 0 // merch index
    }
  }

  async loadMore () {
    if (this.state.isScrolling || this.state.lazyLoading) return
    await this.setState({
      isScrolling: true
    })
    if (this.state.profile.following) {
      await this.loadUserMerch()
      // todo: edge case where two users follow eachother
    } else {
      await this.loadAllMerch()
    }
    await this.setState({
      isScrolling: false
    })
  }

  async loadUserMerch() {
    // todo: edge case where two users follow eachother
    const fans = this.state.profile.following.fans
    if (!fans[this.state.fanIndex] && this.state.index >= this.state.artists.length - 1) { // end of fans, next profile
      let newProfile = this.state.hydratedFans[this.state.profileIndex]
      newProfile.following.fans = newProfile.following.fans.filter(f => {
        let hydratedFanNames = this.state.hydratedFans.map(h => h.profile)
        return !hydratedFanNames.includes(f)
      })
      await this.setState({
        profile: newProfile
      })
      await this.setState({
        fanIndex: 0, // hack
        profileIndex: this.state.profileIndex + 1
      })
    }
    if (this.state.index >= this.state.artists.length - 1) { // end of merch, next fan
      await this.getNextFansArtists()
    }
    const artistToFind = this.state.artists[this.state.index]
    await this.setState({
      index: this.state.index + 1
    })
    if (this.state.artists.slice(0, this.state.index - 1).includes(artistToFind)) {
      return this.loadUserMerch() // only show merch once
    }

    await this.setState({
      lazyLoading: true,
    })
    this.renderMerch()

    const merchResponse = await utils.get('merch', {
      artist:artistToFind,
      profile: this.state.hydratedFans[0].profile
    })
    if (merchResponse[0].merch_id) {
      await this.setState({
        lazyLoading: false,
      })
      this.appendMerch(merchResponse)
      if (this.state.merch.length > 3) return
    } 
    return this.loadUserMerch()
  }

  async getNextFansArtists() {
    const newProfile = await utils.get('profile', {
      profile: this.state.profile.following.fans[this.state.fanIndex]
    })
    await this.setState({
      hydratedFans: this.state.hydratedFans.concat(newProfile),
      fanIndex: this.state.fanIndex + 1,
      artists: this.state.artists.concat(newProfile.following.artists)
    })
    return
  }

  async loadAllMerch () {
    if (this.state.empty) return
    const merch = this.state.merch
    await this.setState({
      lazyLoading: true,
      index: this.state.index + 1
    })
    this.renderMerch()
    const d = new Date()
    const last = merch.length === 0 ? d.getTime() : merch[merch.length - 1].ts
    const merchResponse = await utils.get('merch', {
      last: last
    })
    await this.setState({
      lazyLoading: false
    })
    this.appendMerch(merchResponse)
  }

  async componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);

    const profile = this.props.profile
    if (profile) {
      await this.setState({
        artists: profile.following.artists,
        profile: profile,
        hydratedFans: [profile],
        profileIndex: 1
      })
      this.loadUserMerch()
    } else {
      this.loadAllMerch()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  appendMerch(merchResponse) {
    if (merchResponse.length === 0 || !merchResponse.find(m => m.merch_id)) {
      this.setState({
        empty: true,
        loading: false
      })
      this.renderMerch()
      return false
    }
    const appendedMerch = this.state.merch.concat(merchResponse)
    this.setState({
      merch: appendedMerch,
      loading: false
    })
    this.renderMerch()
  }

  clickMerch(artist, href) {
    // TODO: make this on backened
    // utils.post('clickMerch', {
    //   artist,
    //   href
    // })
  }

  renderMerch () {
    let keyIndex = 0
    let merchMap = this.state.merch.map(merch => {
      keyIndex++
      const location = merch.location.length === 0 ? (<div></div>) : (
        <div style={{paddingTop: '5px'}}>
          ({merch.location})
        </div>
      ) 
      return (
        <div
          className='merch-wrapper'
          key={keyIndex}
          onClick={this.clickMerch.bind(this, merch.artist, merch.href)}
        >
          <a href={utils.buildBandcampHref(merch)} target="_blank" rel="noopener noreferrer"> 
            <div className="merch-img-wrapper">
              <img className='merch-img' src={this.parseImgSrc(merch.img)} alt='tshirt.png'></img> <br /> 
            </div>
            <div className='merch-title'>{this.cropTitle(merch.title)}</div>
          </a>
          <div>
            <span style={this.getMoneyColor(merch.currency_exchange)}>
              {this.getPrice(merch)}
            </span> <br />
            from&nbsp;
            <a 
              href={utils.buildBandcampHref(merch)} 
              style={{'color': 'var(--bandcamp)'}} 
              target="_blank"
              rel="noopener noreferrer"
            > 
              {utils.cropString(merch.artist, 29)}
            </a>
            {location}
          </div>
        </div>
      )
    })
    const merchfeed = (
      <div>
        <div key={this.state.index} className="merch-feed-grid-wrapper">
          <div className="merch-feed-grid">
            {merchMap}
          </div>
        </div>
        <br />
        {this.renderCurrentArtistMsg()}
        <Loader loading={this.state.lazyLoading} padding={60}/>
      </div>
    )
    this.setState({
      merchfeed: merchfeed
    }) 
  }

  renderCurrentArtistMsg () {
    const profile = this.state.profile
    if (!this.state.lazyLoading || !profile.following) {
      return (
        <div></div>
      )
    }
    const currentArtist = this.state.artists[this.state.index - 1]
    const currentProfile = this.profileNameBeingSearchedForMerch()
    return (
      <div key="emptyMerch" style={{ textAlign: 'center', paddingBottom: '40px'}}>
        Finding&nbsp;
        <a href={utils.buildBandcampHref({currentArtist})}
          style={{'color': 'var(--bandcamp)'}}
          target="_blank"
          rel="noopener noreferrer">
          {utils.cropString(currentArtist, 29)}
        </a>
        &nbsp;merch from&nbsp;
        <a href={utils.buildBandcampProfileHref(currentProfile)}
          style={{'color': 'var(--bandcamp)'}}
          target="_blank"
          rel="noopener noreferrer">
          {currentProfile}
        </a>'s profile
      </div>
    )
  }

  profileNameBeingSearchedForMerch() {
    return this.state.fanIndex > 0
      ? this.state.profile.following.fans[this.state.fanIndex - 1]
      : this.state.profile.profile
  }

  cropTitle (title) {
    const maxTitleLength = 31
    const parsedTitle = utils.decodeHtml(title)
    if (parsedTitle.length > maxTitleLength) {
      return parsedTitle.substring(0,maxTitleLength) + '...'
    }
    return parsedTitle
  }

  parseImgSrc (src) { // increase img size via bandcamp
    return src.replace('_37', '_24')
  }

  getPrice (merch) {
    if (merch.currency === 'USD') {
      return (
        <span>
          {merch.currency_symbol}{merch.price} 
        </span>
      )
    }
    return (
      <span>
        {merch.currency_symbol}{merch.price} (${parseInt(merch.currency_exchange)} USD)
      </span>
    )
  }

  getMoneyColor (price, currency) {
    let sPrice = 'low'
    if (price > 20) sPrice = 'mid'
    if (price > 30) sPrice = 'high'
    // if (currency !== 'USD') sPrice = 'foreign'
    return {
      color: `var(--money-${sPrice})`
    }
  }

  handleScroll = (e) => {
    const delta = e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop
    if (delta - 60 <= e.target.scrollingElement.clientHeight) {
      this.loadMore()
    }
  }


  emptyMerchResponse () {
    if (this.state.empty && this.state.profile.following) {
      const artist = this.state.artists[this.state.index]
      return (
        <div key="emptyMerch" style={{ textAlign: 'center', paddingBottom: '40px'}}>
          no merch found for&nbsp;
          <a href={utils.buildBandcampHref({artist})}
            style={{'color': 'var(--bandcamp)'}}
            target="_blank"
            rel="noopener noreferrer"
          >
            {artist} 
          </a>
        </div>
        )
    } else if (this.state.empty) {
      return (
        <div key="emptyMerch" style={{ textAlign: 'center' }}>
          <div className='merch-error'>
            Yikes! <br />
            couldn't find any more merch :(
          </div>
          <br />
          <a href='find' style={{'color': 'var(--bandcamp)'}}>
            Find your own merch
          </a>
          <br /><br /><br />
        </div>
      )
    }
    else {
      return (<div></div>)
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <div className="merch-feed animate__animated animate__fadeInUpBig">
          {this.renderCurrentArtistMsg()}
          <Loader loading={true} padding={60}/>
        </div>
      )
    }
    return (
      <div>
        <div className="merch-feed animate__animated animate__fadeInUpBig">
          <div className='merch-sorter'>
            <div className='merch-sorter-button noSelect'>
              <FontAwesomeIcon icon={faSearch} /> All
            </div>
            <div className='merch-sorter-button noSelect' style={{float: 'right'}}>
              Recently found <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
          
          {this.state.merchfeed}
        </div>
        {this.emptyMerchResponse()}
      </div>
    )
  }
}
