import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import utils from "../utils"

export class TypeAheadDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      bcTypeaheadfeed: [],
      typeAheads: [] // for loading state
    }
  }

  componentDidUpdate (previousProps) {
    if (previousProps.text !== this.props.text) {
      this.getBcTypeahead(this.props.text)
    }
    if (previousProps.loading === true && this.props.loading === false) {
      this.clearDropDown()
    }
  }

  async getBcTypeahead (text) {
    if (this.state.loading) {
      return
    }
    await this.setState({
      loading: true
    })
    this.renderBcTypeahead()
    this.forceUpdate()
    const bcTypeaheadResponse = await utils.get('typeahead', {
      text: text,
    })
    await this.setState({
      loading: false
    })
    if (!bcTypeaheadResponse || !bcTypeaheadResponse.auto || !bcTypeaheadResponse.auto.results) {
      return false
    }
    this.renderBcTypeahead(bcTypeaheadResponse.auto.results)
    if (window.screen.width < 700) {
      this.scrollToForm()
    }
  }

  scrollToForm() {
    const findSubtitle = document.getElementById('find-subtitle')
    findSubtitle.scrollIntoView()
  }

  handleClick (username) {
    if (this.state.loading) {
      return
    }
    let bcUsernameInput = document.getElementById('bcUsername')
    bcUsernameInput.value = username

    let finderButton = document.getElementById('finderButton')
    finderButton.click()
  }

  clearDropDown () {
    this.setState({
      bcTypeaheadfeed: [],
      typeAheads: []
    })
  }

  renderBcTypeahead (typeAheads) {
    if (!typeAheads && this.state.typeAheads.length === 0) {
      return
    }
    if (!typeAheads) {
      typeAheads = this.state.typeAheads
    }
    let loadingTextStyle = this.state.loading ? "typeahead-loading-style" : ""
    let typeaheadfeed = typeAheads.map((t, i) => {
      return (
        <div key={i} className="typeahead-row" onClick={this.handleClick.bind(this, t.username)}>
          <div className="typeahead-col">
            {this.renderBcTypeaheadImg(t)}
          </div>

          <div className="typeahead-col">
            <div className={"typeahead-name " + loadingTextStyle}>
              {utils.cropString(t.name, 27)}
            </div>
            <div className={"typeahead-username " + loadingTextStyle}>
              {utils.cropString(t.username, 27)}
            </div>
          </div>
        </div>
      )
    })

    const bcTypeaheadfeed = (
      <div className="typeahead-wrapper">
        {typeaheadfeed}
      </div>
    )
    this.setState({
      bcTypeaheadfeed: bcTypeaheadfeed,
      typeAheads: typeAheads
    })
  }

  renderBcTypeaheadImg (typeAhead) {
    let className = "typeahead-img"
    if (this.state.loading) className += " typeahead-img-loading"
    if (typeAhead.img) {
      return (
        <img className={className} src={typeAhead.img} alt=""></img>
      )
    } else {
      return (
        <FontAwesomeIcon className={className} icon={faUser} alt=""/>
      )
    }
  }

  render () {
    if (this.props.text === '') {
      return (<div></div>)
    }
    return (
      <div>
        {this.state.bcTypeaheadfeed}
      </div>
    )
  }
}
