import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpider } from '@fortawesome/free-solid-svg-icons'

function About() {
  return (
    <div className="page">
		    <div className="about-spinning-spider app-center animate__animated animate__fadeInUp">
			    <FontAwesomeIcon icon={faSpider} className="home-logo "/>
			 </div>

    	<div className="app-body-wrapper">
		    <div className="app-body animate__animated animate__fadeInUpBig">
		      &nbsp;
		      Merchspider uses human data to crawl through <a style={{'color': 'var(--bandcamp)'}} href="https://bandcamp.com">bandcamp.com</a> gathering merch from all around the world, allowing you to strictly browse merch.
		      <br /> <br />
		      &nbsp;
		      Best of all, 90% of funds go directly to the artist. No slimey record labels involved. Merch you find on Merchspider is guaranteed  to be unique and homemade!
		      <br /> <br />
		      &nbsp;
		      Have a bandcamp account? <a href="find" style={{'color': 'var(--bandcamp)'}}> find your own merch</a> with Merchspider.
		      <div className="app-center">
		      <br /> <br />
		    		Questions, Complements, Complaints? <a href="mailto:merchspider@gmail.com">merchspider@gmail.com</a>
		      <br /> <br /> <br />
		      <a href="/privacy">Privacy Policy</a>
		    	</div>

		    </div>
	    </div>

	    <br /> <br />&nbsp;

    </div>
  )
}

export default About
